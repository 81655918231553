import request from '../request.js'

export default {
    collectOrder(_data){
        return request({
            url: '/user/order/collectOrder?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    getOrderDetailDto(_data){
        return request({
            url: '/user/order/getOrderDetailDto?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    getOrderRecordListDto(_data){
        return request({
            url: '/user/order/getOrderRecordListDto?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    getUserContacts(_data){
        return request({
            url: '/user/order/getUserContacts?data='+ JSON.stringify(_data),
            method: 'get',
        })
    },
    subOrder(_data, _html){
        return request({
            url: '/user/order/subOrder?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html
        })
    },

    // 修改工单等级
    
    editOrderU(_data){
        return request({
            url: '/user/order/editOrderU?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 添加工单反馈
    insertOrderFeedback(_data, _html){
        return request({
            url: '/user/order/insertOrderFeedback?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html,
        })
    },
    // 添加附件
    insertOrderAttachmentReq(_data){
        return request({
            url: '/user/order/insertOrderAttachmentReq?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 升级工单
    orderUpgrade(_data){
        return request({
            url: '/user/order/orderUpgrade?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 修改联系人
    editOrderContact(_data){
        return request({
            url: '/user/order/editOrderContact?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 校验csi和产品
    checkProductReq(_data){
        return request({
            url: '/user/order/checkProductReq?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 结单
    finishOrder(_data,_html){
        return request({
            url: '/user/order/finishOrder?data='+ JSON.stringify(_data),
            method: 'post',
            data: _html,
        })
    },
    // 评价
    subOrderCommentReq(_data){
        return request({
            url: '/user/order/subOrderCommentReq?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 重开工单
    orderOpenAgain(_data){
        return request({
            url: '/user/order/orderOpenAgain?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
    // 编辑工单
    editOrderInfo(_data){
        return request({
            url: '/user/order/editOrderInfo?data='+ JSON.stringify(_data),
            method: 'post',
        })
    },
}